export const hazards = [
    {
        type: "biological",
        subTypes: [
            "algae",
            "avian flu",
            "bacteria",
            "blood products",
            "bodily fluid",
            "faecal biota",
            "flora",
            "flora weed",
            "fungi",
            "hiv/aids",
            "insects / spiders",
            "legionella",
            "livestock",
            "marine life",
            "micro-organisms - non-specific",
            "parasites",
            "rodents",
            "sharps",
            "viruses",
            "wildlife",
        ],
    },
    {
        type: "climatic",
        subTypes: [
            "avalanche",
            "blizzard",
            "drought",
            "earthquake",
            "fire - bushfire/wildfire/grassfire",
            "flood",
            "foggy / misty conditions",
            "hail",
            "heavy rains",
            "heavy snow",
            "ice",
            "landslides",
            "lightning",
            "muddy conditions",
            "rain",
            "snow",
            "storms",
            "tornado",
            "volcanic eruption",
            "volcanic event",
            "wind",
        ],
    },
    {
        type: "electrical",
        subTypes: [
            "ac <110 volts",
            "ac 110-480 volts",
            "ac 240 volts",
            "ac 50 volts",
            "arc flash",
            "control systems",
            "dc <24 volts",
            "dc 110 volts",
            "dc 240 volts",
            "dc earth leakage",
            "dc open circuit",
            "dc short circuit",
            "high voltage (>480 volts)",
            "induced current",
            "loss of power",
            "low voltage",
            "magnetic fields - geomagnetic",
            "magnetic fields intensity",
            "static electricity",
        ],
    },
    {
        type: "environment",
        subTypes: [
            "dredging",
            "ecosystem change",
            "electrical energy consumption",
            "erosion",
            "fuel consumption",
            "land filling",
            "topography modification",
            "vegetation clearing",
            "water abstraction",
            "waterway diversion",
        ],
    },
    {
        type: "ergonomic",
        subTypes: [
            "bending/twisting",
            "hand tool use",
            "load handling",
            "pushing and pulling",
            "repetitive motion actions",
            "vibration - hand/arm",
            "vibration - whole body",
            "work area design",
        ],
    },
    {
        type: "equipment",
        subTypes: [
            "aircraft",
            "ancillary equipment",
            "bicycle / cyclist",
            "cranes",
            "draglines",
            "earthmoving equipment",
            "hazardous material transport",
            "heavy vehicles",
            "light/medium vehicles",
            "marine vessel / ships",
            "mining equipment",
            "molten metal transport",
            "motorcycles",
            "remote controlled equipment",
            "small boat / watercraft",
            "stackers / reclaimers",
            "trains",
        ],
    },
    {
        type: "external",
        subTypes: [
            "arson",
            "bomb",
            "civil unrest",
            "landmines",
            "political unrest",
            "sabotage",
            "unauthorised entry onto site",
            "war",
        ],
    },

    {
        type: "gravity",
        subTypes: [
            "bench stability",
            "dump stability",
            "fall at level",
            "fall from height",
            "fall into depths",
            "falling load",
            "falling object / material",
            "high wall / low walls stability",
            "roll away",
            "roll over",
            "slope stability",
            "underground strata stability",
        ],
    },
    {
        type: "lighting",
        subTypes: ["glare", "high level", "low level"],
    },
    {
        type: "material miscellaneous",
        subTypes: [
            "adhesives",
            "alumina",
            "alumina fluorinated",
            "alumina hydrate",
            "alumina primary",
            "alumina secondary",
            "aluminium",
            "aluminium fluoride",
            "ammonium sulfate",
            "bauxite",
            "bauxite residue",
            "blasting fumes",
            "borates dust",
            "boron",
            "ceramic fibres",
            "chemicals - mixed",
            "coke",
            "copper smelter process dust (esp/whb)",
            "copper sulfate pentahydrate",
            "cryolite (bath)",
            "degreaser",
            "detergents",
            "diesel exhaust particulate (dp/dpm)",
            "diesel exhaust vapours",
            "dross",
            "dust - coal",
            "dust - fugitive emissions from operations",
            "dust - inhalable",
            "dust - organic",
            "dust - respirable",
            "dust - wood",
            "fertilizer",
            "fire suppressant",
            "flocculant",
            "flotation reagents",
            "flux",
            "grease",
            "green house gas",
            "iron chlorate",
            "iron oxide",
            "kaolin",
            "metal leachate (ard)",
            "metallic fumes",
            "mica",
            "molybdenum",
            "non asbestos fibrous silicates (nafs)",
            "oil mist",
            "organic vapours",
            "paint",
            "phosphate",
            "plastic",
            "poly aromatic hydrocarbons",
            "polychlorinated biphenyls",
            "polychlorinated dioxins",
            "polychlorinated furans",
            "potassium chloride",
            "potassium hydrogen silica",
            "process slurry",
            "process water",
            "respirable crystalline silica",
            "rubber",
            "saline water",
            "sewage effluent",
            "sodium chloride",
            "sodium ferrocyanide",
            "sodium gluconate",
            "sodium hydrosulfide",
            "sodium metabisulfate",
            "sodium nitrate",
            "sodium nitrite",
            "sodium sulfate",
            "sodium tetraborate",
            "sodium thiosulfate",
            "soil (contaminated)",
            "suspended solids",
            "talc",
            "tar",
            "thiourea",
            "titanium dioxide slag",
            "welding fumes",
        ],
    },
    {
        type: "material explosive",
        subTypes: ["anfo (ammonium nitrate fuel oil mix)", "explosives - others", "nitroglycerine"],
    },
    {
        type: "material toxic",
        subTypes: [
            "arsenic",
            "arsenic trioxide",
            "asbestos",
            "barium",
            "benzo(a)pyrene",
            "benzyl alcohol",
            "beryllium",
            "biocides",
            "cadmium",
            "carcinogens",
            "chromium iii",
            "chromium vi",
            "coal tar pitch volatiles",
            "copper",
            "ferric sulfate",
            "fluoride",
            "herbicide",
            "insecticide",
            "iso-cyanates",
            "lead",
            "lead carbonate",
            "manganese",
            "mercury",
            "nickel",
            "pesticide",
            "reproductive toxicants",
            "selenium",
            "silver",
            "sodium bifluoride",
            "sodium cyanide",
            "sodium fluoride",
            "sulfuric acid mist",
            "thallium",
            "tributyl tin",
            "trichloroethylene",
            "uranium",
            "vanadium",
            "zinc",
        ],
    },
    {
        type: "material corrosives",
        subTypes: [
            "acetic acid",
            "acid - other",
            "ammonium hydroxide",
            "brake fluid",
            "calcium hydroxide (hydrated lime)",
            "calcium oxide (quick lime)",
            "caustic soda",
            "chromic acid",
            "cresylic acid",
            "hydrochloric acid",
            "hydrofluoric acid",
            "nitric acid",
            "phosphoric acid",
            "sodium carbonate",
            "sodium hydroxide",
            "sodium hypochlorite",
            "sodium sulfide",
            "sulfuric acid",
        ],
    },
    {
        type: "material gases toxic",
        subTypes: [
            "ammonia",
            "arsine gas",
            "carbon monoxide",
            "chlorine",
            "fluorine",
            "hydrogen cyanide",
            "hydrogen fluoride",
            "hydrogen sulfide",
            "nitrogen dioxide",
            "phosgene",
            "sulfur dioxide",
            "sulfur trioxide",
        ],
    },
    {
        type: "material gases flammable",
        subTypes: [
            "acetylene",
            "butane",
            "ethane",
            "ethylene",
            "hydrogen",
            "liquid petroleum gas (propane)",
            "methane",
            "natural gas",
            "propane",
            "volatile organic carbons",
        ],
    },
    {
        type: "material gases other",
        subTypes: [
            "argon",
            "carbon dioxide",
            "chlorofluorocarbons",
            "cryogenic liquids",
            "entonox",
            "heptafluoropropane",
            "nitrogen",
            "nitrogen - liquid cryogenic",
            "nitrous oxide",
            "oxygen",
            "oxygen - liquid crygenic",
            "per fluorocarbons",
            "refrigerant gas",
            "sulphur hexafluoride",
        ],
    },
    {
        type: "material oxidizing agents",
        subTypes: [
            "ammonium nitrate",
            "calcium hypochlorite",
            "organic peroxides",
            "ozone",
            "silver nitrate",
            "sodium oxalate",
            "trichloroisocyanuric acid",
        ],
    },
    {
        type: "mechanical",
        subTypes: [
            "crushing",
            "cutting or severing",
            "grinding",
            "impact",
            "mass and stability",
            "mass and velocity",
            "mechanical strength",
            "moving equipment",
            "pinch points",
            "projectile",
            "puncturing",
            "roll-over",
            "rotating equipment",
            "stored energy",
            "vacuum",
            "wearing / scraping",
        ],
    },
    {
        type: "noise",
        subTypes: ["noise (community issue)", "noise (continuous)", "noise (impact)", "vibration (community issue)"],
    },
    {
        type: "pressure",
        subTypes: [
            "air (eg. tyres)",
            "artesian",
            "ground",
            "hydraulic",
            "hydrostatic",
            "inrush",
            "mechanical",
            "pneumatic",
            "steam",
            "wind blast",
        ],
    },
    {
        type: "radiation",
        subTypes: [
            "gamma / x-rays",
            "radioactive dusts",
            "radioactive gauges",
            "radon, thoron and decay products",
            "x ray apparatus",
            "gamma / x-rays",
            "radioactive dusts",
            "radioactive gauges",
            "radon, thoron and decay products",
            "x ray apparatus",
            "radiation - non-ionising",
            "electromagnetic radiation",
            "infrared",
            "laser",
            "radiofrequency & microwave",
            "ultraviolet",
            "welding flash",
        ],
    },
    {
        type: "social",
        subTypes: [
            "changes in legal systems",
            "cultural heritage - culture",
            "cultural heritage - sites",
            "discrimination",
            "economic / community & social governance capital",
            "economic / community rights",
            "economic / human capital",
            "economic / livelihood - financial capital & productive activities",
            "economic / natural capital",
            "employment",
            "land & infrastructure - access",
            "land & infrastructure - acquisition",
            "relationships - community groups",
            "relationships - government",
            "relationships - media",
            "relationships - non-government organisations (ngos)",
            "stakeholder expectations",
            "subsistence and resource utilization",
        ],
    },
    {
        type: "thermal",
        subTypes: [
            "ambient heat",
            "cold stress",
            "contact - cold gas",
            "contact - cold liquid",
            "contact - cold surface",
            "contact - hot gas",
            "contact - hot liquid",
            "contact - hot surface",
            "explosion - dust",
            "explosion - gas",
            "explosion - mine",
            "explosion - molten material",
            "explosion - underground",
            "extreme cold",
            "extreme heat",
            "fire - surface - fixed plant",
            "fire - surface - mobile plant",
            "fire - underground - fixed plant",
            "fire - underground - mobile plant",
            "heat stress",
            "microwave",
            "molten materials",
            "radiant heat",
            "spontaneous combustion",
        ],
    },
    {
        type: "waste",
        subTypes: [
            "acid generating material",
            "base generating material",
            "biological",
            "clean fill",
            "garden waste",
            "hazardous waste",
            "non hazardous waste",
            "non specific",
            "office/paper/cardboard",
            "oil",
            "putrescible materials",
            "radioactive waste materials",
            "scrap metal",
            "tailings",
            "tyres",
            "water",
            "wood",
        ],
    },
    {
        type: "workplace",
        subTypes: [
            "confined spaces",
            "dry conditions",
            "fixed object",
            "housekeeping",
            "inadequate system design",
            "pedestrians",
            "remote activities/isolation",
            "travel health",
            "uneven or broken ground",
            "ventilation",
            "wet/slick/slippery conditions",
        ],
    },
];
