<template>
  <div class="assessment vh-100">
    <v-container class="py-0 px-0 pb-10" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>

      <v-form>
        <v-card flat tile style="background: #303030">
          <v-card-text class="d-flex pb-0">
            <p class="text-h6 mb-0">Risk Plan Assessment for Henderson Mine Blasting</p>
            <!-- <v-text-field class="step-input pt-1" filled outlined :label="`Task ${taskCount + 1}`" v-model=".name" /> -->
          </v-card-text>
          <div class="pa-4">
            <v-text-field class="step-input pt-1" filled outlined :label="`Project`" v-model="project" />
            <v-text-field class="step-input pt-1" filled outlined :label="`Job`" v-model="job" />
            <v-text-field class="step-input pt-1" filled outlined :label="`Meta 1`" v-model="meta1" />
            <v-text-field class="step-input pt-1" filled outlined :label="`Meta 2`" v-model="meta2" />
            <v-text-field class="step-input pt-1" filled outlined :label="`Meta 3`" v-model="meta3" />
            <v-btn color="primary" @click="submitAI">Submit</v-btn>
            <br /><br />
            <v-divider></v-divider>
          </div>
        </v-card>
        <v-divider></v-divider>
        <v-stepper v-model="stepper" vertical tile flat class="rounded-0">
          <v-stepper-step :complete="stepper > 1" step="1">
            <h3><a v-if="stepper > 1" @click="stepper = 1">Job Tasks</a><span v-else>Job Tasks</span></h3>
            <span class="subtitle-2 font-weight-regular pt-1"
              >Break the job into a sequence of tasks. Each of the tasks should accompany some major task.</span
            >
          </v-stepper-step>

          <v-stepper-content step="1">
            <div>
              <div v-for="(s, taskCount) in tasks" :key="'step-' + taskCount">
                <div>
                  <v-text-field
                    class="step-input pt-1"
                    filled
                    outlined
                    :label="`Task ${taskCount + 1}`"
                    v-model="tasks[taskCount].name"
                  />
                  <div class="ml-4">
                    <v-textarea
                      class="step-input pt-1"
                      filled
                      outlined
                      :label="`Description`"
                      v-model="tasks[taskCount].description"
                    />
                    <div style="max-width: 340px">
                      <div class="d-flex align-center">
                        <v-text-field
                          class="step-input flex-grow-1 mr-2"
                          filled
                          outlined
                          dense
                          label="Expected duration"
                          v-model="tasks[taskCount].duration"
                        />
                        <span class="flex-shrink-0 mb-4 ml-1">Hours</span>
                      </div>
                    </div>

                    <div>
                      <div class="mb-1">Resources</div>
                      <v-row dense>
                        <v-col
                          v-for="(resource, index) in tasks[taskCount].resources"
                          :key="index"
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          class="pa-1"
                        >
                          <v-text-field
                            v-model="tasks[taskCount].resources[index].name"
                            :label="`Resource ${index + 1}`"
                            filled
                            outlined
                            dense
                            hide-details
                            class="step-input"
                            style="width: 100%"
                          >
                            <template v-slot:append>
                              <v-icon @click="removeResource(taskCount, index)"> mdi-close </v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="pa-1">
                          <v-btn color="primary" @click="addResource(taskCount)" outlined style="margin-top: 2px">
                            Add
                            <v-icon right>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>

                <!--
                <div class="subtitle-2 mt-4 mb-2">Identify Hazards that exist for this step</div>
                <v-autocomplete
                  multiple
                  :items="allHazardSubTypes"
                  item-text="text"
                  item-value="value"
                  label="Select Hazards..."
                  chips
                  deletable-chips
                  @change="setSelectHazard(taskCount, $event)"
                  :value="hazardValues.length ? hazardValues : null"
                >
                </v-autocomplete>
                <div>
                  <v-btn text x-small @click="showHazardList = showHazardList == taskCount ? null : taskCount"
                    ><v-icon v-if="showHazardList == taskCount">mdi-chevron-up</v-icon
                    ><v-icon v-else>mdi-chevron-down</v-icon> Hazard List</v-btn
                  >
                </div>

                <div v-if="showHazardList == taskCount">
                  <div v-for="(hazard, typeCount) in hazards" :key="'h-' + typeCount">
                    <div>
                      <v-checkbox
                        hide-details
                        class="my-1 text-capitalize"
                        :label="hazard.type"
                        @change="setHazard(taskCount, hazard.type, $event)"
                        :input-value="tasks[taskCount].hazards.some((v) => v.type == hazard.type)"
                      >
                      </v-checkbox>
                      <div v-if="hazard && hazard.type && s.hazards.some((h) => h.type == hazard.type)">
                        <span
                          v-for="(subType, index) in s.hazards.find((h) => h.type == hazard.type).subTypes"
                          :key="'typedItem-' + index"
                        >
                          <v-chip
                            class="ma-1"
                            close
                            color="grey lighten-1"
                            label
                            outlined
                            small
                            @click:close="setHazardType(taskCount, hazard.type, subType, false)"
                          >
                            {{ subType.name }}
                          </v-chip>
                        </span>
                        <v-chip
                          class="ma-1"
                          color="primary"
                          label
                          small
                          @click="
                            tasks[taskCount].drawer = true;
                            drawerHazardIndex = typeCount;
                          "
                        >
                          <v-icon class="mr-1" small>mdi-plus</v-icon> Add hazards
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <pre>{{ s }}</pre>
                </div>
                <v-navigation-drawer v-model="tasks[taskCount].drawer" fixed temporary right>
                  <h3 class="pa-3 pb-1 text-capitalize" style="background: #222">
                    {{ hazards[drawerHazardIndex].type }}
                  </h3>

                  <div class="mt-3">
                    <div
                      class="ml-3"
                      v-for="(subType, subTypeCount) in hazards[drawerHazardIndex].subTypes"
                      :key="'sub-' + subTypeCount"
                    >
                      <v-checkbox
                        hide-details
                        style="font-size: 14px"
                        class="my-2 py-0 text-capitalize"
                        :label="subType"
                        @change="setHazardType(taskCount, hazards[drawerHazardIndex].type, subType, $event)"
                        :input-value="
                          tasks[taskCount].hazards.find((h) => h.type == hazards[drawerHazardIndex].type)
                            ? tasks[taskCount].hazards
                                .find((h) => h.type == hazards[drawerHazardIndex].type)
                                .subTypes.some((v) => v.name == subType)
                            : null
                        "
                      ></v-checkbox>
                    </div></div
                ></v-navigation-drawer>
                 -->
                <v-divider class="my-4"></v-divider>
              </div>
              <div class="d-flex">
                <v-btn small color="primary" @click="addStep()">Add task </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="stepper = 2"> Continue </v-btn>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-step :complete="stepper > 2" step="2">
            <h3><a v-if="stepper > 2" @click="stepper = 2">Assess hazards</a><span v-else>Assess hazards</span></h3>
            <span class="subtitle-2 font-weight-regular pt-1"
              >Determine the likelihood and consequences of the hazards associated with the tasks.</span
            >
          </v-stepper-step>

          <v-stepper-content step="2">
            <div v-for="(task, taskIndex) in tasks" :key="'stepItem-' + taskIndex">
              <div class="overline" style="line-height: 12px">Step {{ taskIndex + 1 }}</div>
              <div style="font-size: 18px">
                <h4 class="py-2">
                  <v-icon color="orange darken-2">mdi-alert-rhombus-outline</v-icon> {{ task.name }} Risks / Hazards
                </h4>
              </div>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in task.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex" class="mb-8">
                    <!--
                tasks[taskIndex].hazards[hazardIndex].subTypes[subTypeIndex]

              - hazards: An array of objects where each object represents a type of hazard and contains:
                - taskCount: An integer representing the ordering of tasks (if any).
                - type: A string describing the type of hazard.
                - subTypes: An array of objects, where each object describes a sub-type of the hazard and includes:
                  - name: A string describing the sub-type.
                  - severity: An integer from 1-5 representing the potential severity of the hazard.
                  - impact: An integer or null representing the potential impact.
                  - likelihood: An integer representing the likelihood of occurrence.
                  - consequences: A detailed description of potential outcomes if not mitigated.
                  - actions: A comprehensive string describing proactive mitigation strategies.
                  - controls: A detailed string outlining specific control measures to be implemented.
                  - ppe: Required personal protective equipment.
                  - trainingRequired: Any specific training needed to handle this hazard.`,
-->

                    <v-text-field
                      class="step-input pt-1"
                      filled
                      outlined
                      :label="`Hazard/Risk Name`"
                      v-model="type.name"
                    >
                      <template v-slot:prepend> {{ subTypeIndex + 1 }}. </template>
                    </v-text-field>
                    <v-text-field class="step-input pt-1" filled outlined :label="`Type`" v-model="hazard.type">
                      <template v-slot:prepend> &nbsp; &nbsp; </template>
                    </v-text-field>
                    <div class="mt-1 ml-5">
                      <div class="mt-5">
                        Severity:
                        <span :style="'color:' + setTrackColor(type.severity)">
                          {{ severityThumbValue[type.severity] }}
                        </span>
                        <v-slider
                          v-model="type.severity"
                          step="1"
                          :max="4"
                          ticks
                          tick-size="3"
                          :tick-labels="['1', '2', '3', '4', '5']"
                          track-color="primary"
                          track-size=""
                          hide-details
                          :track-fill-color="setTrackColor(type.severity)"
                        >
                        </v-slider>
                      </div>
                      <div class="mt-5">
                        Impact:
                        <span :style="'color:' + setTrackColor(type.impact)">
                          {{ impactThumbValue[type.impact] }}
                        </span>

                        <v-slider
                          v-model="type.impact"
                          step="1"
                          :max="4"
                          ticks
                          tick-size="3"
                          :tick-labels="['1', '2', '3', '4', '5']"
                          track-color="primary"
                          track-size=""
                          :track-fill-color="setTrackColor(type.impact)"
                          hide-details
                        >
                        </v-slider>
                      </div>
                      <div class="mt-5">
                        Likelihood of Occurrence:
                        <span :style="'color:' + setTrackColor(type.likelihood)">
                          {{ likelihoodThumbValue[type.likelihood] }}
                        </span>

                        <v-slider
                          v-model="type.likelihood"
                          step="1"
                          :max="4"
                          ticks
                          tick-size="3"
                          :tick-labels="['Rare', 'Unlikely', 'Possible', 'Likely', 'Virtually']"
                          track-color="primary"
                          track-size=""
                          :track-fill-color="setTrackColor(type.likelihood)"
                          hide-details
                        >
                        </v-slider>
                      </div>
                      <div class="mt-6">
                        <v-textarea
                          class="step-input pt-1"
                          filled
                          outlined
                          auto-grow
                          rows="1"
                          row-height="15"
                          :label="`Consequences`"
                          v-model="type.consequences"
                        />
                      </div>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </div>
              </div>
            </div>
            <v-btn color="primary" @click="stepper = 3"> Continue </v-btn>
            <v-btn text @click="stepper = 1"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="stepper > 3" step="3">
            <h3>
              <a v-if="stepper > 3" @click="stepper = 3">Controls &amp; Recommendations</a
              ><span v-else>Controls &amp; Recommendations</span>
            </h3>
            <div class="subtitle-2 font-weight-regular pt-1">
              Decide what actions are necessary to eliminate, control, or minimize hazards that could lead to accidents,
              injuries, damage to the environment, or illness.

              <v-btn class="mx-4" color="grey" x-small icon @click="dialogInfo = true"
                ><v-icon small color="yellow darken-2" class="mr-1">mdi-information</v-icon>Info</v-btn
              >
            </div>
          </v-stepper-step>

          <v-stepper-content step="3">
            <div class="mt-3 mb-5" v-for="(task, taskIndex) in tasks" :key="'stepItem-' + taskIndex">
              <div class="overline" style="line-height: 12px">Step {{ taskIndex + 1 }}</div>
              <div class="py-2">{{ task.name }}</div>
              <v-divider></v-divider>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in task.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                    <span class="text-capitalize">
                      <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                        >mdi-alert-box</v-icon
                      >
                      {{ type.name }} : {{ hazard.type }}</span
                    >
                    <div class="ml-6 mt-2 mb-4">
                      <v-row class="mt-2 mb-4">
                        <v-col cols="auto" class="text-center">
                          <v-progress-circular
                            :value="getRiskRating(type.likelihood, type.impact)"
                            :size="150"
                            :width="15"
                            color="yellow darken-2"
                          >
                            <v-row justify="center" align="center" class="fill-height">
                              <v-col class="text-center">
                                <strong>{{ Math.ceil(getRiskRating(type.likelihood, type.impact)) }}%</strong>
                                <div class="caption">Risk Rating</div>
                              </v-col>
                            </v-row>
                          </v-progress-circular>
                        </v-col>

                        <v-col class="d-flex flex-column justify-center">
                          <v-progress-linear
                            rounded
                            striped
                            class="mb-4"
                            :color="setTrackColor(type.likelihood) + ' darken-4'"
                            :value="type.likelihood != 0 ? Math.ceil((type.likelihood / 5) * 100) : 5"
                            height="15"
                          >
                            <span class="overline">Likelihood</span>
                          </v-progress-linear>

                          <v-progress-linear
                            rounded
                            striped
                            class="mb-4"
                            :color="setTrackColor(type.impact) + ' darken-4'"
                            :value="Math.ceil((type.impact / 5) * 100)"
                            height="15"
                          >
                            <span class="overline">Impact</span>
                          </v-progress-linear>

                          <v-progress-linear
                            rounded
                            striped
                            class="mb-4"
                            :color="setTrackColor(type.severity) + ' darken-4'"
                            :value="type.severity != 0 ? Math.ceil((type.severity / 5) * 100) : 5"
                            height="15"
                          >
                            <span class="overline">Severity</span>
                          </v-progress-linear>
                        </v-col>
                      </v-row>

                      <v-text-field
                        hide-details
                        label="Control measures to be implemented"
                        class="step-input pt-1 mt-2"
                        filled
                        outlined
                        v-model="type.controls"
                      ></v-text-field>
                      <v-text-field
                        hide-details
                        label="Proactive mitigation strategies"
                        class="step-input pt-1 mt-2"
                        filled
                        outlined
                        v-model="type.actions"
                      ></v-text-field>

                      <v-text-field
                        hide-details
                        label="Required personal protective equipment (PPE)"
                        class="step-input pt-1 mt-2"
                        filled
                        outlined
                        v-model="type.ppe"
                      ></v-text-field>

                      <v-text-field
                        hide-details
                        label="Specific training needed to handle this hazard."
                        class="step-input pt-1 mt-2"
                        filled
                        outlined
                        v-model="type.trainingRequired"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <v-btn color="primary" @click="stepper = 4"> Continue </v-btn>
            <v-btn text @click="stepper = 2"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">
            <h3>Summary and Review</h3>
          </v-stepper-step>
          <v-stepper-content step="4">
            <div v-for="(task, taskIndex) in tasks" :key="'stepItem-' + taskIndex">
              <div class="overline" style="line-height: 12px">Step {{ taskIndex + 1 }}</div>
              <div>{{ task.name }}</div>
              <v-divider></v-divider>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in task.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                    <span class="text-capitalize">
                      <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                        >mdi-alert-box</v-icon
                      >
                      {{ type.name }} : {{ hazard.type }}</span
                    >
                    <div class="ml-6 mt-2">
                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        color="light-green darken-3"
                        :value="Math.ceil((type.likelihood / 5) * 100)"
                        height="15"
                      >
                        <span class="overline">Likelihood</span>
                      </v-progress-linear>

                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        color="orange darken-4"
                        :value="Math.ceil((type.impact / 5) * 100)"
                        height="15"
                      >
                        <span class="overline">Impact</span>
                      </v-progress-linear>
                      <label class="overline">Risk Controls</label>
                      <div class="mb-3">
                        {{ type.controls }}
                      </div>
                      <label class="overline">Actions to reduce risk</label>
                      <div class="mb-3">{{ type.actions }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <v-btn color="primary" @click="stepper = 1"> Submit Risk Assessment </v-btn>
            <v-btn text @click="stepper = 3"> Back </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-form>
    </v-container>
    <br />
    <br />

    tasks:
    <pre>
            {{ tasksAIString }}
            </pre
    >

    <v-dialog v-model="dialogInfo" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="primary" class="rounded-0 pr-4">
          <v-toolbar-title>More Info</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialogInfo = false"> <v-icon class="mr-2">mdi-close</v-icon> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div>
          <v-container>
            <v-card>
              <v-card-title>Risk Management Controls</v-card-title>
              <v-card-text>
                <ol>
                  <li>
                    <strong>Avoidance:</strong>
                    <ul>
                      <li>Deciding not to use a particularly hazardous chemical in a manufacturing process.</li>
                      <li>
                        Choosing not to undertake a construction project in an area with extreme weather conditions.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Transfer:</strong>
                    <ul>
                      <li>Purchasing liability insurance for potential workplace accidents.</li>
                      <li>
                        Subcontracting high-risk tasks to specialized companies with better equipment or expertise.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Mitigation:</strong>
                    <ul>
                      <li>Installing safety guards on heavy machinery to reduce the risk of injuries.</li>
                      <li>
                        Implementing dust suppression systems in mining operations to minimize respiratory hazards.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Acceptance:</strong>
                    <ul>
                      <li>
                        Acknowledging that some level of noise is unavoidable in certain manufacturing processes, but
                        ensuring it's within legal limits.
                      </li>
                      <li>Accepting minor wear and tear on equipment as part of normal operations.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Diversification:</strong>
                    <ul>
                      <li>Using multiple suppliers for critical raw materials to reduce supply chain risks.</li>
                      <li>Developing various product lines to reduce dependence on a single market.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Monitoring:</strong>
                    <ul>
                      <li>Implementing real-time air quality monitoring systems in chemical plants.</li>
                      <li>
                        Using predictive maintenance techniques to monitor equipment health and prevent breakdowns.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Contingency Planning:</strong>
                    <ul>
                      <li>Developing emergency response plans for potential industrial accidents.</li>
                      <li>Having backup power systems in place for critical operations in case of power failures.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Training and Education:</strong>
                    <ul>
                      <li>
                        Providing comprehensive safety training for all employees, including proper use of Personal
                        Protective Equipment (PPE).
                      </li>
                      <li>Offering ongoing education on new safety protocols and equipment operation.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Documentation and Reporting:</strong>
                    <ul>
                      <li>Maintaining detailed logs of equipment maintenance and inspections.</li>
                      <li>Implementing a system for reporting and investigating near-misses and incidents.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Other:</strong>
                    <ul>
                      <li>Implementing a behavior-based safety program to encourage safe practices.</li>
                      <li>Using virtual reality simulations for high-risk training scenarios.</li>
                    </ul>
                  </li>
                </ol>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import { hazards } from "@/constants/hazardsData";
export default {
  name: "Assessment",
  mixins: [Helpers],
  computed: {
    allHazardSubTypes() {
      let v = [];
      this.hazards.forEach((h) => {
        h.subTypes.forEach((sub) => {
          v.push({ type: h.type, subType: sub, text: h.type + " " + sub, value: { type: h.type, subType: sub } });
        });
      });
      return v;
    },
  },
  watch: {
    // "tasks[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood"(n, o) {
    //   console.log(n);
    //   if (n > o) {
    //     this.trackColor = "success";
    //   } else if (n < o) {
    //     this.trackColor = "error";
    //   }
    // },
  },
  data: () => ({
    existing: {
      step: 1,
      name: "Preparation and Safety Check",
      description:
        "Ensure all testing and diving equipment is functional and appropriate for underwater use. Verify communication tools and backup systems.",
      risks: [
        {
          likelihood: 2,
          consequence: 5,
          riskAssessmentControl: "Equipment Check",
          mitigationActions:
            "Conduct thorough pre-dive checks on all equipment. Ensure backup systems are operational.",
        },
        {
          likelihood: 3,
          consequence: 4,
          riskAssessmentControl: "Appropriate Clothing",
          mitigationActions:
            "Replace boxer shorts with suitable diving suit to maintain body temperature and protect against underwater hazards.",
        },
      ],
    },
    changeto: [
      {
        name: "The first task",
        hazards: [
          {
            taskCount: 0,
            type: "climatic",
            subTypes: [
              { name: "ice", impact: 1, likelihood: 2, actions: "wear chains", controls: "Transfer" },
              { name: "heavy snow", impact: 2, likelihood: 1, actions: "stay inside", controls: "Avoidance" },
            ],
          },
          {
            taskCount: 0,
            type: "electrical",
            subTypes: [{ name: "ac 50 volts", impact: null, likelihood: 0, actions: "sizzle", controls: "Monitoring" }],
          },
        ],
        drawer: false,
      },
    ],

    // project: "",
    // job: "",
    // meta1: "",
    // meta2: "",
    // meta3: "",
    project: "Magnetic Particle Testing (MPT) for Underwater",
    job: "Non-Destructive Testing (NDT) of Welded Joints",
    meta1: "sea water",
    meta2: "depth of 10 meters",
    meta3: "wearing boxer shorts",
    responseAI: null,
    responseAIString: "",
    tasksAI: null,
    tasksAIString: null,
    tasksAIJSON: null,
    /////////
    dialogInfo: false,
    trackColor: "primary",
    // likelihoodIcons: ["😭", "😢", "☹️", "🙁", "😐", "🙂", "😊", "😁", "😄", "😍"],
    impactThumbValue: ["Negligible", "Minor", "Moderate", "Major", "Severe"],
    severityThumbValue: ["Insignificant", "Minor", "Moderate", "Major", "Catastrophic"],
    likelihoodThumbValue: ["Rare", "Unlikely", "Possible", "Likely", "Almost certain"],
    pullKey: 0,
    pushes: null,
    selectedItem: null,
    stepper: 1,
    showHazardList: null,
    assessment: false,
    hazardCollection: true,
    tasks: [{ name: "", resources: [], hazards: [], drawer: false }],
    riskControls: [
      "Avoidance",
      "Transfer",
      "Mitigation",
      "Acceptance",
      "Diversification",
      "Monitoring",
      "Contingency planning",
      "Training and education",
      "Documentation and reporting",
      "Other",
    ],
    hazards,
    selectedHazards: [],
    drawerHazardIndex: 0,
    hazardValues: {},
  }),

  methods: {
    getRiskRating(likelihood, impact) {
      likelihood = Math.max(1, Math.min(5, likelihood));
      impact = Math.max(1, Math.min(5, impact));
      const rawScore = likelihood * impact;
      const score = ((rawScore - 1) / 24) * 100;
      return Math.round(score);
    },
    addResource(taskCount) {
      this.tasks[taskCount].resources.push({ name: "" });
    },
    removeResource(taskCount, index) {
      this.tasks[taskCount].resources.splice(index, 1);
    },
    async submitAI() {
      const project = this.project;
      const job = this.job;
      const meta1 = this.meta1;
      const meta2 = this.meta2;
      const meta3 = this.meta3;

      // const project = "Magnetic Particle Testing (MPT) for Underwater";
      // const job = "Non-Destructive Testing (NDT) of Welded Joints";
      // const meta1 = "sea water";
      // const meta2 = "depth of 10 meters";
      // const meta3 = "wearing boxer shorts";

      // Construct the prompt
      // const prompt = `
      // You are to generate a JSON-formatted list of operations for a specific project. Each operation should include a step number, a name, a description, and an array of risks associated with that step. Each risk should detail the likelihood and consequence, both as integers from 1 to 5, and include a risk assessment control and mitigation actions.
      // Create a JSON list of operations for the project: ${project}, task: ${job}, specifics: ${meta1}, ${meta2}, ${meta3}.
      // `;

      const apiKey =
        "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";

      const payload = {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content:
              // "You are to generate a JSON-formatted list of operations for a specific project. Each operation should include a step number, a name, a description, and an array of risks associated with that step. Each risk should detail the likelihood and consequence, both as integers from 1 to 5, and include a risk assessment control and mitigation actions.",
              //"You are to generate a JSON-formatted list of tasks for a specific project. Each task should include:\n- name: A string that describes the task.\n- hazards: An array of objects where each object represents a type of hazard and contains:\n  - taskCount: An integer representing the ordering of tasks (if any).\n  - type: A string describing the type of hazard.\n  - subTypes: An array of objects, where each object describes a sub-type of the hazard and includes:\n    - name: A string describing the sub-type.\n    - impact: An integer or null representing the potential impact.\n    - likelihood: An integer representing the likelihood of occurrence.\n    - actions: A string describing the mitigation actions.\n    - controls: A string describing the control measures.",

              // INIT
              // `You are to generate a JSON-formatted list of tasks for a specific project. Each task should include:
              // - name: A string that describes the task.
              // - hazards: An array of objects where each object represents a type of hazard and contains:
              //   - taskCount: An integer representing the ordering of tasks (if any).
              //   - type: A string describing the type of hazard.
              //   - subTypes: An array of objects, where each object describes a sub-type of the hazard and includes:
              //     - name: A string describing the sub-type.
              //     - impact: An integer or null representing the potential impact.
              //     - likelihood: An integer representing the likelihood of occurrence.
              //     - actions: A string describing the mitigation actions.
              //     - controls: A string describing the control measures.`,

              `You are to generate a JSON-formatted list of tasks for a specific project. Each task should include:
              - name: A string that describes the task.
              - description: A detailed explanation of what the task entails and its importance.
              - duration: Estimated time to complete the task in hours.
              - resources: An array of objects, list of required resources or equipment, includes:
                 - name: A string of the resource name
              - prerequisites: Any tasks that must be completed before this one.
              - hazards: An array of objects where each object represents a type of hazard and contains:
                - taskCount: An integer representing the ordering of tasks (if any).
                - type: A string describing the type of hazard.
                - subTypes: An array of objects, where each object describes a sub-type of the hazard and includes:
                  - name: A string describing the sub-type.
                  - severity: An integer from 0-4 representing the potential severity of the hazard.
                  - impact: An integer from 0-4 representing the potential impact.
                  - likelihood: An integer from 0-4 representing the likelihood of occurrence.
                  - consequences: A detailed description of potential outcomes if not mitigated.
                  - actions: A comprehensive string describing proactive mitigation strategies.
                  - controls: A detailed string outlining specific control measures to be implemented.
                  - ppe: Required personal protective equipment.
                  - trainingRequired: Any specific training needed to handle this hazard.`,
          },
          {
            role: "user",
            content: `Create a JSON list of operations for the project: ${project}, task: ${job}, specifics: ${meta1}, ${meta2}, ${meta3}.`,
          },
        ],
        temperature: 0.5,
        top_p: 1,
      };

      try {
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        this.responseAI = data;

        if (data.choices && data.choices.length > 0 && data.choices[0].message && data.choices[0].message.content) {
          const content = data.choices[0].message.content;
          console.log(data);

          console.log(content);

          const jsonString = content.replace(/^```json\s*/, "").replace(/\s*```$/, "");

          console.log(jsonString);

          try {
            this.tasksAIString = jsonString;
            this.tasksAIJSON = JSON.parse(jsonString);
            console.log(this.tasksAIJSON.tasks);
            this.tasks = this.tasksAIJSON.tasks;
          } catch (parseError) {
            console.error("Error parsing JSON:", parseError);
            this.tasksAI = null; // or set to a default value
          }
        } else {
          console.error("Unexpected response structure:", data);
          this.tasksAI = null; // or set to a default value
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.responseAI = null;
        this.tasksAI = null; // or set to default values
      }

      // await fetch("https://api.openai.com/v1/chat/completions", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${apiKey}`,
      //   },
      //   body: JSON.stringify(payload),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.responseAI = data;
      //     this.responseAIString = JSON.stringify(data, null, 2);

      //     console.log("API Response:", data);
      //     // Handle the response data here
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
    },

    ////////////////////////

    setTrackColor(val) {
      if (val > 3) {
        return "red";
      } else if (val > 2) {
        return "orange";
      } else if (val > 1) {
        return "yellow";
      } else if (val > 0) {
        return "light-green";
      } else {
        return "light-blue";
      }
    },
    addHazard(hazard) {
      this.selectedHazards.push({ hazard });
    },
    addStep() {
      this.tasks.push({ name: "", hazards: [], drawer: false });
    },
    formatHazards(taskCount) {
      let v = [];
      this.tasks[taskCount].hazards.forEach((h) => {
        h.subTypes.forEach((sub) => {
          v.push({
            type: h.type,
            subType: sub.name,
            text: h.type + " " + sub.name,
            value: { type: h.type, subType: sub.name },
          });
        });
      });
      if (v) {
        this.hazardValues = v;
      }
    },
    setSelectHazard(taskCount, e) {
      this.tasks[taskCount].hazards = [];
      if (this.tasks[taskCount].hazards) {
        e.forEach((s) => {
          if (
            !this.tasks[taskCount].hazards.find((h) => h.type == s.type)?.subTypes.some((sub) => sub.name == s.subType)
          ) {
            this.tasks[taskCount].hazards.push({ taskCount: taskCount, type: s.type, subTypes: [] });
            const sub = { name: s.subType, impact: null, likelihood: null, actions: null, controls: null };
            this.tasks[taskCount].hazards.find((h) => h.type == s.type)?.subTypes.push(sub);
          }
        });
      }
    },
    setHazard(taskCount, hazard, e) {
      //console.log(taskCount + "|" + hazard + "|" + typeCount + "|" + e);
      if (e) {
        //this.selectedHazards.push({ taskCount: taskCount, type: hazard, subTypes: [] });
        this.tasks[taskCount].hazards.push({ taskCount: taskCount, type: hazard, subTypes: [] });
      } else {
        const idx = this.tasks[taskCount].hazards.findIndex((h) => h.type == hazard);
        this.tasks[taskCount].hazards.splice(idx, 1);
      }
    },
    setHazardType(taskCount, type, subType, e) {
      if (e) {
        const sub = { name: subType, impact: null, likelihood: null, actions: null, controls: null };
        this.tasks[taskCount].hazards.find((h) => h.type == type)?.subTypes.push(sub);
      } else {
        const hazard = this.tasks[taskCount].hazards.find((h) => h.type == type);
        const idx = hazard.subTypes?.findIndex((t) => t.name == subType);
        hazard.subTypes.splice(idx, 1);
      }
      this.formatHazards(taskCount);
    },
    assessHazards() {
      this.assessment = true;
      this.hazardCollection = false;
    },
    showSubTypes(hazard) {
      this.selectedHazards.push(hazard);
    },
    refresh() {},
    appRefresh() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
    showAsset(type) {
      this.asset = type;
      if (type == "video") {
        this.$refs.videoPlayer.play();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.step-input input {
  //font-size: 16px;
  //font-weight: bold;
  margin-top: 0 !important;
}
.v-slider__tick-label {
  font-size: 11px;
}
.v-slider--horizontal .v-slider__track-container {
  height: 4px;
}
</style>
